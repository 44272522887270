<template>
  <div id="swt-loading-css-container">
    <div class="swt-loading-css-loader"></div>
  </div>
</template>

<script>
export default {
  name: "SwtLoadingCss",
};
</script>

<style lang="scss" scoped>
#swt-loading-css-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483647;
  width: 100vw;
  height: 100vh;
  transition: all 1s;
  background-color: rgba(128, 128, 128, 0.6);
}
.swt-loading-css-loader,
.swt-loading-css-loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.swt-loading-css-loader {
  position: relative;
  top: 50%;
  left: 50%;
  font-size: 10px;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-animation: load-anime 1.1s infinite linear;
  animation: load-anime 1.1s infinite linear;
}
@-webkit-keyframes load-anime {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes load-anime {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
