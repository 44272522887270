import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
// XSRF Token Cookie
axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.withCredentials = true;
// XSRF Token Header(v1.6.2)
// axios.defaults.xsrfHeaderName = "X-Xsrf-Token"
// axios.defaults.withXSRFToken = true;

import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import { library } from "@fortawesome/fontawesome-svg-core";
// import { faPaste } from "@fortawesome/free-solid-svg-icons";
// library.add(faPaste);
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
