import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import modules from './modules'

import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
  modules,
  state: {

  },
  mutations: {

  },
  actions: {

  },
  plugins: [
    // localStorage
    // createPersistedState(),
    // sessionStorage
    createPersistedState({
      key: 'swt',
      storage: window.sessionStorage
    }),
  ]
})
