<template>
  <section>
    <swt-logo />
  </section>
</template>

<script>
import SwtLogo from "../components/SwtLogo";
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "AboutPage",
  components: {
    SwtLogo,
  },
  data() {
    return {};
  },
  created() {
    this.getAccessRecomme();
  },
  computed: {
    ...mapGetters({
      getData: "view/getData",
    }),
  },
  methods: {
    getAccessRecomme: function () {
      const url = process.env.VUE_APP_API_URL + "accessRecomme/get";
      this.axios
        .get(url)
        .then((response) => {
          if (response.data) {
            const data = response.data;
            let result = [];
            data.forEach((site) => {
              result.push({
                name: site["name"],
                url: site["url"],
                detail: site["detail"],
              });
            });
            this.setData({ key: "recomme", value: result });
          }
        })
        .catch(() => {});
    },
    ...mapMutations({
      setData: "view/setData",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>