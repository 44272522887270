import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import store from "../store";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "top",
      component: require("../views/About").default,
    },
    {
      path: "/login",
      name: "login",
      component: require("../views/Login").default,
    },
    {
      path: "/search/multi",
      name: "search_multi",
      component: require("../views/SearchMulti").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "/search/hotkeyword",
      name: "search_hotkeyword",
      component: require("../views/SearchHotkeyword").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "/search/seo",
      name: "search_seo",
      component: require("../views/SearchSeo").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "/access/send",
      name: "access_send",
      component: require("../views/AccessSend").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "/access/recomme",
      name: "access_recomme",
      component: require("../views/AccessRecomme").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "/access/income",
      name: "access_income",
      component: require("../views/AccessIncome").default,
      beforeEnter: (to, from, next) => {
        next(store.getters["auth/checkActive"](to.name));
      },
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.dispatch("auth/getUser");
  const isLogin = store.getters["auth/check"];
  // ログイン状態でログイン画面に遷移しようとしたらトップへ
  if (isLogin && to.path === "/login") {
    next("/");
  }
  // ログインしていないならログイン画面に遷移する
  else if (!isLogin && !to.path.match(/\/login(\/.*)?/)) {
    next("/login");
  } else {
    next();
  }
});

export default router;
