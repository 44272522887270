<template>
  <div id="swt-loading-container">
    <div>
      <div v-if="!img" class="swt-loading-loader"></div>
      <div v-else class="swt-loading-img-loader">
        <img v-bind:src="require('../assets/img/' + img)" v-bind:alt="text" />
      </div>
      <span v-text="text"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwtLoading",
  props: {
    text: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
#swt-loading-container > div {
  position: relative;
  width: fit-content;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.swt-loading-img-loader {
  position: relative;
  width: fit-content;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.swt-loading-loader,
.swt-loading-loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.swt-loading-loader {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 5px;
  border-top: 1em solid rgba(128, 128, 128, 0.2);
  border-right: 1em solid rgba(128, 128, 128, 0.2);
  border-bottom: 1em solid rgba(128, 128, 128, 0.2);
  border-left: 1em solid rgba(128, 128, 128, 0.8);
  -webkit-animation: load-anime 1.1s infinite linear;
  animation: load-anime 1.1s infinite linear;
}
@-webkit-keyframes load-anime {
  0% {
    -webkit-transform: translateX(-50%) rotate(0deg);
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translateX(-50%) rotate(360deg);
    transform: translateX(-50%) rotate(360deg);
  }
}
@keyframes load-anime {
  0% {
    -webkit-transform: translateX(-50%) rotate(0deg);
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translateX(-50%) rotate(360deg);
    transform: translateX(-50%) rotate(360deg);
  }
}
</style>
