<template>
  <article id="app">
    <swt-nav-login />
    <main>
      <div class="view-main">
        <router-view />
      </div>
    </main>
    <swt-nav />
  </article>
</template>

<script>
// import "./assets/css/reset.css";
import "./assets/css/style.css";
import SwtNav from "./components/SwtNav";
import SwtNavLogin from "./components/SwtNavLogin";
export default {
  name: "app",
  components: {
    SwtNav,
    SwtNavLogin,
  },
};
</script>

<style lang="scss"></style>

<style lang="scss" scoped></style>
