export default {
  namespaced: true,
  state: {
    data: {},
  },
  // state, value / state, { payload }
  mutations: {
    setData: (state, { key, value }) => {
      // state.data[key] = value;
      state.data = {
        ...state.data,
        [key]: value,
      }
    },
    clearData: (state) => {
      state.data = {};
    },
  },
  // context, value / context, { payload }
  // context.(state, getters, rootState, rootGetters, commit, dispatch)
  actions: {},
  // state, getters, rootState, rootGetters
  // (state, getters, rootState, rootGetters) => (value)
  getters: {
    getData: (state) => (key) => {
      return state.data[key] ? state.data[key] : null;
    },
    getTitle: (state) => state.data["title"]? state.data["title"] : null,
  },
};
