<template>
  <section>
    <swt-loading-css v-if="load" />

    <div id="cont-wrap">
      <p
        class="comment-line"
        v-html="getParagraphText(detail)"
      ></p>

      <div class="toggle-radio">
        <input
          type="radio"
          id="on"
          v-bind:value="true"
          v-model="result.send_access_active"
          v-on:change="setAccessSend('send_access_active')"
        />
        <input
          type="radio"
          id="off"
          v-bind:value="false"
          v-model="result.send_access_active"
          v-on:change="setAccessSend('send_access_active')"
        />
        <div class="switch">
          <label for="on">ON</label>
          <label for="off">OFF</label>
          <span></span>
        </div>
      </div>

      <div class="flex-cont2">
        <div>
          <div class="access-number-title">現在のアクセス数</div>
          <p>{{ result.send_access_count }}</p>
        </div>
        <div>
          <div class="access-number-title">残りアクセス数</div>
          <p>{{ send_access_count_remaining }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwtLoadingCss from "../components/SwtLoadingCss";
export default {
  name: "AccessSend",
  components: {
    SwtLoadingCss,
  },
  data: function () {
    return {
      detail: `
登録したURLにアクセスを送付いたします！
自動で集客されながら収益化へ！
                `,
      result: {
        send_access_active: false,
        send_access_count_total: 0,
        send_access_count: 0,
      },
      load: false,
    };
  },
  computed: {
    send_access_count_remaining: function () {
      let remaining =
        this.result.send_access_count_total - this.result.send_access_count;
      if (remaining < 0) {
        remaining = 0;
      }
      return remaining;
    },
  },
  created() {
    this.getAccessSend();
  },
  methods: {
    getParagraphText: function (text) {
      return text.trim().replace(/\r?\n/g, "<br/>");
    },
    getAccessSend: function () {
      if (this.load !== false) return;
      const url = process.env.VUE_APP_API_URL + "sendAccess/get";
      this.load = true;
      this.axios
        .get(url)
        .then((response) => {
          this.load = false;
          if (response.data) {
            const data = response.data;
            this.result = {
              send_access_active: data["send_access_active"],
              send_access_count_total: data["send_access_count_total"],
              send_access_count: data["send_access_count"],
            };
          }
        })
        .catch(() => {
          this.load = false;
          this.result.send_access_active = false;
        });
    },
    setAccessSend: function (key) {
      if (this.load !== false || this.result[key] === undefined) return;
      const url = process.env.VUE_APP_API_URL + "sendAccess/set";
      let data = {};
      data[key] = this.result[key];
      this.load = true;
      this.axios
        .post(url, data)
        .then(() => {
          this.load = false;
          this.getAccessSend();
        })
        .catch(() => {
          this.load = false;
          this.getAccessSend();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-cont2 {
  display: -webkit-flex;
  display: var(--display-flex);
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: var(--center);
  -webkit-align-items: stretch;
  align-items: stretch;
  margin-top: 1rem;
  gap: 10px;
}
.flex-cont2 > div {
  border: 1px solid var(--lightgray-color);
  border-radius: 5px;
  padding: 10px;
}
.flex-cont2 > div {
  width: 48%;
}
.flex-cont2 > div > p {
  line-height: var(--base-line);
  text-align: var(--center);
  margin-top: 1rem;
}

h2 {
  font-weight: var(--font-weight-400);
  text-align: var(--center);
  color: var(--red-color);
  margin-top: 1rem;
}
.access-number-title {
  text-align: var(--center);
  border-bottom: 1px solid var(--lightgray-color);
  padding-bottom: 10px;
}
.toggle-radio {
  margin-top: 1rem;
}
.switch {
  position: relative;
  width: 150px;
  height: 50px;
  text-align: center;
  margin: 0.5rem auto var(--base-zero);
  display: block;
  background: #00bc9c;
  transition: all 0.2s ease;
  border-radius: 25px;
}
.switch span {
  position: absolute;
  width: 20px;
  height: 4px;
  top: 50%;
  left: 50%;
  margin: -2px 0px 0px -4px;
  background: #fff;
  display: block;
  transform: rotate(-45deg);
  transition: all 0.2s ease;
}
.switch span:after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 12px;
  margin-top: -8px;
  background: #fff;
  transition: all 0.2s ease;
}

input[type="radio"] {
  display: none;
}
.switch label {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.2);
  width: 60px;
  line-height: 50px;
  transition: all 0.2s ease;
}
label[for="on"] {
  position: absolute;
  left: 0px;
  height: 20px;
}
label[for="off"] {
  position: absolute;
  right: 0px;
}
#off:checked ~ .switch {
  background: #eb4f37;
}
#off:checked ~ .switch span {
  background: #fff;
  margin-left: -8px;
}
#off:checked ~ .switch span:after {
  background: #fff;
  height: 20px;
  margin-top: -8px;
  margin-left: 8px;
}
#on:checked ~ .switch label[for="on"] {
  color: #fff;
}
#off:checked ~ .switch label[for="off"] {
  color: #fff;
}
</style>