<template>
  <footer v-if="this.isLogin">
    <nav class="menu">
      <ul class="gmenu">
        <li class="gmenus">
          <div
            class="openbtn"
            v-bind:class="{ 'active': isActiveMenu }"
            v-on:click="toggleMenu()"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </li>
        <nav
          id="g-nav"
          v-bind:class="{ 'panelactive': isActiveMenu }"
        >
          <div id="g-nav-list">
            <ul>
              <li v-for="(page, navIndex) in this.navItems" v-bind:key="navIndex">
                <router-link
                  v-on:click.native="toggleMenu(page.display)"
                  v-bind:to="{ name: page.name }"
                  ><font-awesome-icon v-bind:icon="page.icon" />{{ page.display }}</router-link
                >
              </li>
            </ul>
          </div>
        </nav>
      </ul>
    </nav>
  </footer>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "SwtNav",
  data() {
    return {
      isActiveMenu: false,
    };
  },
  methods: {
    toggleMenu(title) {
      this.isActiveMenu = !this.isActiveMenu
      if (title) this.setTitle(title)
    },
    setTitle(title) {
      this.setData({ key: "title", value: title })
    },
    ...mapActions({
      userLogout: "auth/logout",
    }),
    ...mapMutations({
      setData: "view/setData",
    }),
  },
  computed: {
    navItems() {
      if (!this.isLogin) return []

      return [
        {
          name: "search_multi",
          display: "トレンドブログ",
          icon: "magnifying-glass",
        },
        {
          name: "search_hotkeyword",
          display: "トレンドワード",
          icon: "arrow-trend-up",
        },
        {
          name: "search_seo",
          display: "SEO",
          icon: "arrow-pointer",
        },
        {
          name: "access_send",
          display: "アクセス送付",
          icon: "square-up-right",
        },
        {
          name: "access_recomme",
          display: "お勧めサイト",
          icon: "desktop",
        },
        {
          name: "access_income",
          display: "特別サイト",
          icon: "money-bill-trend-up",
        },
      ].filter((d) => d.name === "top" || this.userData["active_" + d.name])
    },
    ...mapGetters({
      isLogin: "auth/check",
      userData: "auth/getUser",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>