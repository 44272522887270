<template>
  <button type="button" v-bind:class="css" v-on:click="clipboardCopy">
    <span v-if="!icon">{{ text }}</span>
    <font-awesome-icon v-if="icon" v-bind:icon="icon" />
  </button>
</template>

<script>
export default {
  name: "SwtButtonClipboardCopy",
  props: {
    target_id: {
      type: String,
      default: null,
    },
    target_text: {
      type: String,
      default: "",
    },
    css: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "COPY",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  methods: {
    clipboardCopy: function () {
      const element = document.createElement("textarea");
      element.style.position = "fixed";
      element.style.opacity = "0";

      if (this.target_id !== null) {
        element.textContent = document.getElementById(
          this.target_id
        ).textContent;
      } else {
        element.textContent = this.target_text;
      }

      document.body.appendChild(element);
      element.select();
      document.execCommand("copy");
      document.body.removeChild(element);
    },
  },
};
</script>

<style lang="scss" scoped></style>
