<template>
  <section>
    <div id="cont-wrap">
      <p
        class="comment-line"
        v-html="getParagraphText(detail)"
      ></p>

      <div class="income-wrap">
        <ul class="income-list">
          <li>
            <strong>遠隔サポート</strong>
          </li>
          <li
            v-for="(site, index) in list_remote"
            v-bind:key="'r' + index"
          >
            <a v-bind:href="site.href">
              <div class="icons">
                <font-awesome-icon v-bind:icon="site.icon" />
                <span>{{ site.display }}</span>
              </div>
            </a>
          </li>

          <li>
            <strong>AI機能</strong>
          </li>
          <li
            v-for="(site, index) in list_chatgpt"
            v-bind:key="'c' + index"
          >
            <a v-bind:href="site.href">
              <div class="icons">
                <font-awesome-icon v-bind:icon="site.icon" />
                <span>{{ site.display }}</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AccessIncome",
  data: function () {
    return {
      detail: `
高額プランのみ利用可！しっかりと活用しながら収益化していきましょう！
                `,
      list_remote: [
        {
          display: "Windows",
          icon: ["fab", "windows"],
          href: "https://anydesk.com/ja/downloads/windows",
        },
        {
          display: "iOS",
          icon: ["fab", "apple"],
          href: "https://apps.apple.com/us/app/anydesk/id1176131273",
        },
        {
          display: "Android",
          icon: ["fab", "android"],
          href: "https://play.google.com/store/apps/details?id=com.anydesk.anydeskandroid&pli=1",
        },
      ],
      list_chatgpt: [
        {
          display: "Windows",
          icon: ["fab", "windows"],
          href: "https://chat.openai.com/auth/login",
        },
        {
          display: "iOS",
          icon: ["fab", "apple"],
          href: "https://apps.apple.com/app/openai-chatgpt/id6448311069",
        },
        {
          display: "Android",
          icon: ["fab", "android"],
          href: "https://play.google.com/store/apps/details?id=com.openai.chatgpt",
        },
      ],
    };
  },
  methods: {
    getParagraphText: function (text) {
      return text.trim().replace(/\r?\n/g, "<br/>");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>